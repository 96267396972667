input:focus,input:visited,textarea:focus,textarea:visited,select:visited,select:focus{outline: none;}
select{appearance: none;-webkit-appearance: none;-moz-appearance: none;}

/* toggle switch css start  */

.switch {position: relative;display: inline-block;width: 60px;height: 34px;}
.switch input { opacity: 0;width: 0;height: 0;}  
.slider-box {position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color: #ccc;-webkit-transition: .4s;transition: .4s;}
.slider-box:before {position: absolute;content: "";height: 26px;width: 26px;left: 4px;bottom: 4px;background-color: white;-webkit-transition: .4s;transition: .4s;}
input:checked + .slider-box {background-color: #2196F3;}
input:focus + .slider-box {box-shadow: 0 0 1px #2196F3;}
input:checked + .slider-box:before {-webkit-transform: translateX(26px);-ms-transform: translateX(26px);transform: translateX(26px);}
.slider-box.round {border-radius: 34px;}
.slider-box.round:before {border-radius: 50%;}




body{   
    font-family: 'Montserrat', sans-serif;
}
.container{
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding:0 20px;
}

.login{background: url(../image/Admin-signin-bg.jpg)no-repeat;background-size: 100% 100%;@apply w-screen h-screen;}
.login-cover{@apply flex flex-row w-full items-center justify-between;padding:88px 60px 0;}
.lg-left{@apply flex ;}
.lg-right .login-box{padding:32px;background-color:#fff;width: 448px;@apply flex flex-col relative justify-start;}
.login-box h1{font-size: 32px;line-height: 43px;@apply w-full text-gold-1 font-medium capitalize;margin-bottom: 32px;font-family: 'Playfair Display', serif;}
.login-box .login-form{@apply w-full flex flex-col;}


.login-form .error-group{background-color: #FCE3E3;@apply flex flex-row items-center font-medium;padding:14px 10px;font-size: 16px;line-height: 19px;}
.login-form .error-group img{margin-right: 16px;}
.login-form .error-group p{color:#CA0C0C;margin: 0px;}
.login-form .form-group{@apply flex flex-col w-full relative cursor-pointer;margin-bottom: 16px;}
.login-form .form-group .icon{@apply absolute;left:12px;top:11px;}
.login-form .form-group .edit-icon{@apply absolute;right:13px;top:12px;}
.login-form .form-group .form-control{padding:14px 14px 14px 50px;@apply text-grey-2;font-size: 16px;line-height: 18px;background-color: rgba(236, 236, 236, 1);border:1px solid transparent;}
.login-form .form-group .form-control::placeholder{@apply capitalize text-grey-2 font-medium;}
.form-control:focus{border:1px solid #131313 !important;background-color: transparent !important;}

.login-form .submit{@apply w-full;margin-top: 32px;}
.login-form .submit .button{@apply inline-block w-full duration-500 flex flex-row justify-between items-center bg-black-1 font-medium text-gold-1 capitalize;font-size: 16px;padding:14px 15px;line-height: 20px;}
.login-form .submit .button:hover{opacity: 0.8;}
.login-form .resend-otp{margin:16px 0 32px;}
.login-form .resend-otp p{@apply text-gold-1 font-medium cursor-pointer duration-500;font-size: 16px;line-height: 19px;}
.login-form .resend-otp p:hover{@apply text-black-1;}
.login-form .resend-otp label{@apply text-grey-2 font-medium;}

.error .form-control{border:1px solid #CA0C0C !important;background-color:transparent !important;}
.error .icon svg path{fill:#CA0C0C;}


/* header style css start  */

.header{padding:8px 0;@apply w-full bg-black-3;}
.header .container{@apply flex flex-row justify-between items-center;}
.logo{@apply flex;}
.header-right{@apply flex flex-row items-center cursor-pointer relative;padding:8px 0px;}
.header-right > .icon{width:32px;height: 32px;@apply flex flex-row justify-center items-center;background-color:#ECECEC;border-radius: 100px;}
.header-right .user-name{color:#fff;font-size:16px;line-height: 20px;@apply font-medium capitalize relative;margin: 0 0 0 15px;padding-right:25px;}
.header-right .user-name:after{content: "";@apply absolute right-0;background: url(../image/arrow-down.svg);width:9px;height: 6px;top:8px;}
.header-right .dropdown-menu{@apply absolute right-0 duration-500 bg-white;top:100%;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);-moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);width: 192px;z-index: 11;height: 0px}
.header-right .dropdown-menu a{@apply inline-block duration-500 w-full text-black-1 font-medium;line-height: 17px;visibility: hidden;opacity: 0;padding:12px 16px;}
.header-right .dropdown-menu a:hover{background-color: #EFEFEF;}
.header-right.open .dropdown-menu{opacity: 1;padding: 8px 0px;height: auto;}
.header-right.open .dropdown-menu a{visibility: visible;opacity: 1;}

/* header style css end  */
.download-csv-message{@apply fixed bg-black-1 flex flex-row items-center;padding:16px 16px;left:24px;bottom:24px;}
.download-csv-message p{@apply text-white font-medium;font-size: 14px;line-height: 17px;margin-right: 16px;}
.download-csv-message .button{background-color: transparent;border:none;padding:5px;}
.user-management-block{padding:30px 0px;@apply w-full;}
.user-management-block .main-title{@apply flex justify-between w-full;}
.user-management-block .main-title .title{@apply flex flex-row items-center;}
.user-management-block .main-title .title h1{@apply font-medium text-gold-1;font-size: 32px;line-height: 42px;font-family: 'Playfair Display', serif;}
.user-management-block .download-icon{margin-left: 16px;width: 32px;height: 32px;border-radius: 4px;border: 1px solid #ECECEC;@apply flex items-center justify-center flex-row duration-500 cursor-pointer relative;}
.user-management-block .download-icon:hover{@apply bg-gold-1;}
.download-icon .download-tooltip{@apply absolute duration-500 bg-black-1 font-medium text-white;left:-12px;width: 123px;padding:8px;font-size: 14px;line-height: 17px;top:40px;visibility: hidden;opacity: 0;pointer-events: none;}
.download-icon:hover .download-tooltip{opacity: 1;visibility: visible;}
.serch-filter{@apply flex flex-row items-center;}
.serch-filter .form-group{@apply relative;margin-right: 24px;width: 384px;}
.serch-filter .form-group .form-control{@apply bg-grey-1 w-full;padding:7px 20px 7px 50px;border:1px solid transparent;}
.serch-filter .form-group .form-control::placeholder{color:#707070;@apply font-medium;font-size: 16px;line-height: 20px;}
.serch-filter .form-group .search{@apply absolute;left:12px;top:7px;}
.serch-filter .add-user-box.filter{margin-right: 24px;cursor: inherit;}
.serch-filter .add-user-box.filter img{margin-right: 19px;}

.add-user-box{@apply flex flex-row relative items-center capitalize cursor-pointer duration-500 font-medium;font-size: 16px;line-height: 18px;border: 1px solid #131313;padding:10px 16px;}
.add-user-box p{margin-right: 16px;@apply text-gold-1 font-medium;}
.add-user-box:hover{@apply bg-black-1;}
.add-user-box.filter {border:none;}
.add-user-box.filter .dropdown-menu .switch{margin-left: 10px;opacity: 0;@apply duration-500;}
.add-user-box.filter.open .dropdown-menu .switch{opacity: 1;}
.add-user-box.filter .switch{width: 26px;height: 12px;}
.add-user-box.filter .switch .slider-box{border-radius: 100px;background-color:#bfbfbf;}
.add-user-box.filter.open .switch .slider-box{background-color: #10B981;}
.account-form .form-group select.form-control{background: #ECECEC url(../image/down-arrow.svg)no-repeat center right 24px;}
.account-form .form-group select.form-control:focus,
.account-form .form-group select.form-control:visited{background-color: transparent;}


.add-user-box.filter .slider-box:before{width: 8px;height: 8px;border-radius: 100px;bottom:2px;left:3px;}
.add-user-box.filter .switch input:checked + .add-user-box.filter  .slider:before {-webkit-transform: translateX(12px);-ms-transform: translateX(12px);transform: translateX(12px);}
.add-user-box.filter .switch input:checked + .add-user-box.filter .slider-box:before{transform: translateX(12px);}
.add-user-box.filter p{margin: 0px 0 0 10px;@apply text-black-1;}
.add-user-box.filter:hover{background-color: transparent;}

 input:checked + .slider-box:before{transform: translateX(12px);}

.usermanagement-table{margin-top: 33px;@apply w-full;}
.usermanagement-table table{@apply w-full;}
.usermanagement-table table tr{box-shadow: 0px 1px 0px #ECECEC;}
.usermanagement-table table tr th{text-align: left;padding-bottom: 16px;text-transform:uppercase;color:#707070;@apply font-medium;font-size: 16px;line-height: 19px;}
.usermanagement-table table tr th .filter{display: inline-block;margin:0 0 0 16px;position: relative;top:-4px;}
.usermanagement-table table tr td{padding:16px 0px;}
.usermanagement-table table tr td p{@apply text-grey-2;font-size: 16px;line-height: 19px;}
.usermanagement-table table tr td span{@apply text-black-1 font-medium inline-block;font-size: 16px;}
.usermanagement-table table tr td label{@apply text-black-1 font-medium inline-block;font-size: 16px;text-transform: capitalize;margin: 0 0 8px;}

.usermanagement-table table .user-icon{width:40px;height: 40px;@apply bg-grey-1;border-radius: 100px;@apply flex items-center justify-center;margin-left: 16px;}
.usermanagement-table table tr td .edit-icon{width:40px;height: 40px;background-color: transparent;border-radius: 4px;@apply flex items-center justify-center cursor-pointer duration-500;}
.usermanagement-table table tr td .edit-icon:hover{background-color: #EFEFEF;}

.usermanagement-table .load-more-btn{@apply flex justify-center w-full;margin-top: 32px;}
.usermanagement-table .load-more-btn button{text-transform: capitalize;@apply bg-grey-1 text-black-1 w-full inline-block font-medium;font-size: 16px;line-height: 19px;padding:8px;max-width: 344px;}


/* account modal css start  */

.account-modal .account-body{max-width:712px;padding:16px;}
.account-body .close-btn svg path{stroke:#000;}
.account-body .close-btn:focus,.account-body .close-btn:active{outline: none;box-shadow: none;}
.account-body .title {margin-bottom: 24px;@apply text-left w-full;}
.account-body .title h1{@apply text-gold-1 font-medium;font-size: 32px;line-height: 46px;font-family: 'Playfair Display', serif;}

.account-detail-cover{@apply flex flex-col w-full;}
.account-tab{@apply flex flex-col w-full;}
.account-body .tab{@apply flex flex-row w-full;padding: 0px;margin: 0px;border-bottom:1px solid #ECECEC;}
.account-body .tab li{margin-right: 24px;padding:0 0 16px;cursor: pointer;font-family: 'Playfair Display', serif;color:#707070;}
.account-body .tab li:last-child{margin-right: 0px;}
.account-body .tab li.active{border-bottom: 2px solid #131313;color:#131313;}

.account-tab .tab-content{@apply flex flex-col w-full;}
.profile-tab ul{@apply flex flex-col w-full;padding:0px;}
.profile-tab ul li{@apply flex flex-row w-full;border-bottom:1px solid #ECECEC;padding:9px;}
.profile-tab ul li:last-child{border:none;}
.profile-row{@apply flex flex-row w-full items-center;padding:9px 0px;}




.profile-row .profile-col{width: 40%;@apply text-left;}
.profile-row .profile-col label{color:#707070;font-size: 14px;line-height: 17px;@apply font-medium capitalize;}
.profile-row .profile-col:nth-child(2){padding-right: 15px;}
.profile-row .profile-col:last-child{width: 20%;@apply flex justify-end items-center;}
.profile-row .update-row{@apply flex flex-row;}
.profile-row .update-row button{background-color: #EFEFEF;border-radius: 4px;width: 40px;height: 40px;@apply flex items-center justify-center;margin: 0 4px;}
.profile-row .edit button{width:40px;height: 40px;border-radius: 4px;@apply flex items-center duration-500 justify-center;}
.profile-row .edit button svg path{@apply duration-500;}
.profile-row .edit button:hover{background-color: #EFEFEF;}
.profile-row .edit button:hover svg path{fill: #131313;}
.profile-col .form-control{padding:8px 16px;border:1px solid transparent;font-size: 14px;@apply capitalize font-medium w-full;line-height: 17px;background-color: transparent;}
.profile-col .form-control:focus{border-color:transparent !important;}
.profile-row.edit-row .form-control{border:1px solid #131313;}
.profile-row.edit-row .form-control:focus{border-color:#131313 !important;}
.profile-col .form-control:focus{outline: none}
.purchase-history-tab{@apply flex flex-row w-full;}
.purchase-plan{@apply flex flex-col w-full;}
.purchase-plan-title{@apply flex flex-col text-left w-full;margin: 16px 0px;}
.purchase-plan-title h2{font-family: 'Playfair Display', serif;color:#131313;font-size: 24px;line-height: 42px;@apply font-medium;}
.profile-col p{@apply font-medium capitalize;font-size: 14px;line-height: 17px;}
.profile-col p.green{color:#10A868;padding:8px 16px;}
.profile-col p.red{color:#CA0C0C;padding:8px 16px;}
.overlay-bg{background-color:rgba(19, 19, 19, 0.6);}

/* add user popup css start  */

.account-form{@apply flex flex-col w-full;}
.account-form .form-group{@apply flex flex-row items-center;border-bottom: 1px solid #ECECEC;padding:8px 36px 8px 16px;}
.account-form .form-group label{width:50%;@apply capitalize text-left text-grey-1 font-medium;font-size:14px;line-height: 17px;}
.account-form .form-group .form-control{padding:8px 16px;width:50%;background-color:#ECECEC;font-size: 16px;@apply font-medium;line-height: 19px;border:1px solid #ECECEC;}
.account-form .form-group .react-datepicker-wrapper{width: 50%;}
.account-form .form-group .react-datepicker__input-container input{padding:8px 16px;width:100%;background-color:#ECECEC;font-size: 16px;@apply font-medium;line-height: 19px;border:1px solid #ECECEC;}
.account-form .form-group .react-datepicker__input-container input::placeholder{font-size: 16px;}
.account-form .form-group .form-group-inner{@apply flex justify-start flex-col text-left;width: 50%;}
.form-group-inner .form-control{width: 100% !important;}
.form-group-inner .error{color:#E55252;font-weight: 500;font-size: 14px;@apply inline-block;margin-top: 2px;}

.account-form .submit-row{@apply w-full flex flex-row justify-end;margin-top: 24px;}
.account-form .secondary-button{@apply duration-500 text-gold-1 font-medium capitalize;font-size: 16px;line-height: 19px;padding:12px 10px;border: 1px solid #131313;margin-right: 24px;}
.account-form .primary-button{@apply text-gold-1 duration-500 font-medium capitalize;font-size: 16px;line-height: 19px;padding:12px 10px;border: 1px solid #131313;background-color:#131313;}
.account-form .secondary-button:hover{@apply bg-black-1;}
.account-form .primary-button:hover{background-color: transparent;}

.create-account-modal{max-width: 612px !important;}


/* date picker css start  */
.react-datepicker{border:none !important;}
.react-datepicker__month-container{box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);-moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);-webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);border:none;}
.react-datepicker__navigation{top:12px !important;}
.react-datepicker__header{padding:12px 0px !important;background-color: transparent !important;border-bottom: 1px solid #EDEDED;}
.react-datepicker__current-month{padding: 0px 0 4px !important;}
.react-datepicker__day--selected{background-color: #131313 !important;@apply text-gold-1 !important;}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before{border-top-color: #fff !important;}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{border-bottom-color: #fff !important;}
.react-datepicker__day--outside-month{color:#dbdada !important;}

/* date picker css end  */


.disabled-row .form-control:focus,.disabled-row .form-control:visited{border-color:transparent !important;background-color: #ECECEC !important;pointer-events: none;}